import request from '@/api/request.js'

/**
 * @Description: 温层属性
 */

// 分页查询
export function pageWmsTemperature(data) {
    return request({
        url: '/api/wmsTemperature/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsTemperature(data) {
    return request({
        url: '/api/wmsTemperature/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsTemperature(data) {
    return request({
        url: '/api/wmsTemperature/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsTemperature(data) {
    return request({
        url: '/api/wmsTemperature/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsTemperature(data) {
    return request({
        url: '/api/wmsTemperature/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsTemperature(data) {
    return request({
        url: '/api/wmsTemperature/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsTemperature(data) {
    return request({
        url: '/api/wmsTemperature/batchDelete',
        method: 'POST',
        data
    })
}

